//@import '~antd/dist/antd.less';
//@import '~antd/dist/antd.dark.less';

// container
.container{
  width: 100%;
  position:relative;
  margin-left:auto;
  margin-right:auto;
  padding-right:15px;
  padding-left:15px;
  padding-top: 20px;
  padding-bottom: 30px;
}
@media (min-width: 476px){
  .container{
    padding-right:15px;
    padding-left:15px
  }
}
@media (min-width: 768px){
  .container{
    padding-right:15px;
    padding-left:15px
  }
}
@media (min-width: 992px){
  .container{
    padding-right:15px;
    padding-left:15px
  }
}
@media (min-width: 1200px){
  .container{
    padding-right:15px;
    padding-left:15px
  }
}
@media (min-width: 476px){
  .container{
    width:100%
  }
}
@media (min-width: 768px){
  .container{
    width:720px;
    max-width:100%
  }
}
@media (min-width: 992px){
  .container{
    width:960px;
    max-width:100%
  }
}
@media (min-width: 1200px){
  .container{
    width:1140px;
    max-width:100%
  }
}
@media (min-width: 1400px){
  .container{
    width:1340px;
    max-width:100%
  }
}

// layout
.hoz-scroll {
  overflow-x: scroll;
}

// spin
.spin-container {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

// grid
.row {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 30px;
}

// bar
.bar-container {
  width: 100%;
  //margin-top:16px;
  margin-bottom:16px;
}
.bar-container:before, .bar-container:after {
  content:"";
  display:table;
  clear:both
}
.bar-grey {
  color:rgb(255, 255, 255)!important;
  background-color:#505050!important;
  position: relative;
}
.bar-blue {
  color:rgb(255, 255, 255)!important;
  background-color:#004c89!important;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 4px 0px;
}
.bar-red {
  color:rgb(255, 255, 255)!important;
  background-color:#ff0000!important;
  position: absolute;
  width: 3px;
  height: 100%;
}
.bar-text {
  position: relative;
  padding-left: 10px;
  white-space: nowrap;
  z-index: 1000;
}

// image slider
// ref: https://codepen.io/nxworld/pen/ZYNOBZ
.img-item {
  height: 200px;
  border-radius: 5px;
  margin: 0px 10px;
  cursor: pointer;

  opacity: 1;
	-webkit-animation: flash 1.5s;
	animation: flash 1.5s;

  -webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

.img-item:hover {
	opacity: .8;

  -webkit-transform: scale(1.05);
	transform: scale(1.05);
}

// others
.site-layout-background {
  background: #333;
}

.site-header-background {
  background: #777;
}

.upload-button-container {
  width: 100%;
  padding: 10%
}

.left-image {
  width: 100%;
  padding: 10px;
  //border: 5px solid #555;
}

.left-image-container {
  display: flex;
  overflow: auto;
  height: 100%;
}